var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("MobileMenu"),
      _c(
        "div",
        {
          staticClass: "header--wrapper",
          class: { "header--wrapper__fixed": _vm.isMobile },
        },
        [
          _c("div", { staticClass: "navigation container" }, [
            _c(
              "div",
              { staticClass: "logo", on: { click: _vm.handleClickLogo } },
              [
                _c("Icon", {
                  attrs: {
                    name: "LogoPortTranzit",
                    width: _vm.LogoIconWidth,
                    height: "auto",
                  },
                }),
              ],
              1
            ),
            _c(
              "nav",
              { staticClass: "navigation__item" },
              [
                !_vm.isSuperAdmin && !_vm.isTablet
                  ? _c(
                      "el-menu",
                      { attrs: { mode: "horizontal" } },
                      [
                        _vm._l(_vm.getNavigationList, function (item) {
                          return _c(
                            "el-menu-item",
                            {
                              key: item.id,
                              attrs: { index: String(item.name) },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSettings(item.name)
                                },
                              },
                            },
                            [
                              !!item.icon
                                ? _c(
                                    "div",
                                    { staticClass: "menu-icon" },
                                    [
                                      _c("Icon", {
                                        attrs: { name: item.icon },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" " + _vm._s(item.title) + " "),
                            ]
                          )
                        }),
                        _c("el-menu-item", { on: { click: _vm.logout } }, [
                          _c(
                            "div",
                            { staticClass: "menu-icon" },
                            [
                              _c("Icon", {
                                attrs: {
                                  name: "IconGoToTerminal",
                                  width: "18px",
                                  height: "18px",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.logout.apply(null, arguments)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    )
                  : _c(
                      "div",
                      [
                        _c("Icon", {
                          attrs: {
                            name: "IconMenuLine",
                            width: _vm.BurgerIconWidth,
                            height: "auto",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleOpenMobileMenu.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }