<template>
  <div>
    <MobileMenu />
    <div
      class="header--wrapper"
      :class="{ 'header--wrapper__fixed': isMobile }"
    >
      <div class="navigation container">
        <div class="logo" @click="handleClickLogo">
          <Icon name="LogoPortTranzit" :width="LogoIconWidth" height="auto" />
        </div>

        <nav class="navigation__item">
          <el-menu v-if="!isSuperAdmin && !isTablet" mode="horizontal">
            <el-menu-item
              v-for="item in getNavigationList"
              :key="item.id"
              :index="String(item.name)"
              @click="handleSettings(item.name)"
            >
              <div v-if="!!item.icon" class="menu-icon">
                <Icon :name="item.icon" />
              </div>
              {{ item.title }}
            </el-menu-item>
            <el-menu-item @click="logout">
              <div class="menu-icon">
                <Icon
                  name="IconGoToTerminal"
                  width="18px"
                  height="18px"
                  @click.native="logout"
                />
              </div>
            </el-menu-item>
          </el-menu>

          <div v-else>
            <Icon
              name="IconMenuLine"
              :width="BurgerIconWidth"
              height="auto"
              @click.native="handleOpenMobileMenu"
            />
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AUTH_LOGOUT,
  GET_IS_INCOMPLETE_PARKING,
  GET_IS_MOBILE,
  SET_DRAWER,
} from '@/store/actions'
import { MAIN_MENU } from '@/constants/drawers'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  navigationList,
  navigationRootList,
  navigationViewerList,
} from '@/constants/navigaiton'
import Icon from '@/UI/icon/Icon'
import MobileMenu from '@/components/drawer/mobile-menu/MobileMenu'

export default {
  name: 'Navigation',
  components: { MobileMenu, Icon },
  data() {
    return { visible: false, navigationList }
  },
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
      hideParkmanPage: GET_IS_INCOMPLETE_PARKING,
    }),
    getNavigationList() {
      if (this.isViewer) {
        return navigationViewerList
      }

      return navigationList
    },
    LogoIconWidth() {
      return this.isMobile ? 'auto' : '286'
    },
    BurgerIconWidth() {
      return this.isMobile ? '18' : '44'
    },
  },
  methods: {
    ...mapActions({ logout: AUTH_LOGOUT }),
    ...mapMutations({ setDrawer: SET_DRAWER }),
    handleClickLogo() {
      const urlHome = 'Home'

      urlHome !== this.$route.name && this.$router.push({ name: urlHome })
    },
    handleSettings(name) {
      this.visible = true
      this.$router.push({
        name,
      })
      this.$emit('resetExtraMenu')
    },
    handleOpenMobileMenu() {
      this.setDrawer({
        name: MAIN_MENU,
        visible: true,
        data: {
          items: this.getNavigationListDrawer(),
        },
      })
    },
    getNavigationListDrawer() {
      if (this.isSuperAdmin) {
        if (!this.hideParkmanPage) {
          return navigationRootList
        }
        let parkmanIndex = navigationRootList.findIndex(
          item => item.name === 'Parkman',
        )
        let supervisorIndex = navigationRootList.findIndex(
          item => item.name === 'Supervisor',
        )
        let list = navigationRootList.slice()

        list.splice(parkmanIndex, 1)
        list.splice(supervisorIndex, 1)

        return list
      }
      if (this.isViewer) {
        return navigationViewerList
      }

      return navigationList
    },
  },
}
</script>

<style lang="sass">
.header--wrapper
  z-index: 2
  min-height: 42px
.header--wrapper__fixed
  position: fixed
  top: 0
  left: 0
  right: 0
  min-height: 42px
.navigation
  align-items: center
  .logo
    cursor: pointer
    padding: 8px 0 8px
    &>span
      color: #000
  .el-menu.el-menu--horizontal
    border-bottom: 0
    background-color: $color-blue-primary
    height: 100%
    &>.el-menu-item
      display: flex
      align-items: center
      font-size: 16px
      font-weight: bold
      margin: 0
      color: $color-white
      padding: 0 16px
      border-right: 1px solid rgba(255, 255, 255, 0.3)
      height: 100%
      &.is-active
        background-color: $color-blue
      &>.menu-icon
        margin-right: 8px
      &:last-child
        margin-right: 0
        border-right: none
    & .el-menu-item:not(.is-disabled):focus,
    & .el-menu-item:not(.is-disabled):hover
      background-color: $color-blue-light-2
  &__item
    display: flex
    justify-content: center
    align-items: center
  svg
    cursor: pointer
</style>
